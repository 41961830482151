export enum Flags {
  COVEO_SEARCH = 'as-coveo-search',
  HOMEPAGE_FEATURE = 'asset-store-new-homepage',
  SHOW_PURCHASED_STATE = 'asset-store-show-product-purchased-state',
  NEW_PDP = 'as-new-pdp',
  NEW_LANDING_PAGES = 'as-new-landing-pages',
  COVEO_EXPERIMENT_PIPELINE = 'as-coveo-experiment-pipeline',
  NON_CURATED_PLP_WITH_ML = 'as-coveo-non-curated-plp-with-ml',
  NON_CURATED_PLP = 'as-non-curated-plp',
  OPEN_RESULTS_IN_NEW_TAB = 'as-asset-results-new-tab',
  INCREASE_DEFAULT_RESULTS_PER_PAGE = 'as-increase-default-coveo-results-per-page',
  LISTING_RESULTS_WIDTH = 'as-listing-results-width',
}

export const FLAG_CONTROL = 'control';
export const FLAG_TREATMENT = 'treatment';

// keys specific for LISTING_RESULTS_WIDTH
export const FLAG_CONTROL_SOLR = 'control-solr';
export const FLAG_CONTROL_COVEO = 'control-coveo';
export const FLAG_TREATMENT_A = 'treatment-a';
export const FLAG_TREATMENT_B = 'treatment-b';

// keys specific for NON_CURATED_PLP_WITH_ML
export const FLAG_TREATMENT_NO_ML = 'treatment-no-ml';
export const FLAG_TREATMENT_ML = 'treatment-ml';

export const FEATURE_FLAG_ON = 'on';
export const FEATURE_FLAG_OFF = 'off';
