import { useEffect, useState } from 'react';

const SCROLL_BUFFER_PIXELS = 10;
const TOP_OF_PAGE_THRESHOLD = 110;

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(
    null
  );

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? 'down' : 'up';

      if (
        direction &&
        direction !== scrollDirection &&
        (scrollY - lastScrollY > SCROLL_BUFFER_PIXELS ||
          scrollY - lastScrollY < -SCROLL_BUFFER_PIXELS)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;

      // reset scroll direction if we're at the top of the page
      if (scrollY < TOP_OF_PAGE_THRESHOLD) {
        setScrollDirection(null);
      }
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener

    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  useEffect(() => {
    // If tabbing backwards on the page, when elements are at the top of the page its possible they will be hidden by the sticky header, so Shift+Tab doesn't scroll the page
    // We check if Shift+Tab is pressed, if it is then check if there's an active element, that the element is not within the header element, and if that element is behind the header.
    // If it is, then we hide the header so the element isn't blocked.
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === 'Tab') {
        const activeElement = document.activeElement as HTMLElement;
        const headerElement = document.getElementsByTagName('header')[0];
        const isActiveElementWithinHeader =
          headerElement.contains(activeElement);
        if (
          activeElement &&
          !isActiveElementWithinHeader &&
          activeElement.getBoundingClientRect().top < TOP_OF_PAGE_THRESHOLD
        ) {
          setScrollDirection('down');
        }
      }
    };

    // use keyup, as on keydown the document.activeElement is still the previous element
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  return scrollDirection;
}
